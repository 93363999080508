"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    &.badge-hidden {
        display: inline-block;
        padding: 3px 6px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: rgba(26, 43, 77, 0.6);
        border-radius: 8px;
        border: 1px solid rgba(255, 221, 87, 0.8);
        background-color: rgba(246, 248, 251, 1);
    }
`;
