"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileInputBorder(border = {}) {
    const { width, type = 'around', radius, hoverColor, color, focusColor, } = border;
    return `
        ${hoverColor ? `--input-border-color-hovered: ${hoverColor};` : ''}
        ${color ? `--input-border-color: ${color};` : ''}
        ${focusColor ? `--input-border-color-active: ${focusColor};` : ''}
        ${radius ? `--input-border-radius: ${radius};` : ''}
        ${width ? `--input-border-weight: ${width};` : ''}
        ${type === 'around' ? `
            --input-border-width: var(--input-border-weight);
            --input-border-bottom-width: 0;
        ` : ''}
        ${type === 'bottom' ? `
            --input-border-width: 0;
            --input-border-bottom-width: var(--input-border-weight);
    ` : ''}
    `;
}
// eslint-disable-next-line complexity
function compileInputStyleString(branding) {
    const { input = {} } = branding;
    const { border, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, lineHeight, colors: { bg, placeholder, text } = {}, fontSize, padding, restriction: { bottom, right, fontSize: restrictionFontSize, fontWeight: restrictionFontWeight, lineHeight: restrictionLineHeight } = {}, textarea: { autogrowTop, fontSize: textAreaFontSize, lineHeight: textAreaLineHeight, maxHeight, minHeight, padding: textAreaPadding } = {}, mobile: { restriction: { bottom: mobileBottom, right: mobileRight } = {} } = {} } = input;
    return `
        {
            ${lineHeight ? `--input-dependent-line-height: ${lineHeight};` : ''}
            ${focusVisibleBorderColor ? `--input-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
            ${fontSize ? `--input-dependent-font-size: ${fontSize};` : ''}
            ${textAreaFontSize ? `--long-text-textarea-font-size: ${textAreaFontSize};` : ''}
            ${textAreaLineHeight ? `--long-text-textarea-line-height: ${textAreaLineHeight};` : ''}
            ${maxHeight ? `--long-text-textarea-max-height: ${maxHeight};` : ''}
            ${minHeight ? `--long-text-textarea-min-height: ${minHeight};` : ''}
            ${minHeight ? `--long-text-textarea-autogrow-min-height: ${minHeight};` : ''}
            ${textAreaPadding ? `--long-text-padding: ${textAreaPadding};` : ''}
            ${autogrowTop ? `--long-text-textarea-autogrow-top: ${autogrowTop};` : ''}
            ${bg ? `--input-control-bg-color: ${bg};` : ''}
            ${placeholder ? `--input-control-placeholder-color: ${placeholder};` : ''}
            ${text ? `--input-control-color: ${text};` : ''}
            ${padding ? `--input-padding: ${padding};` : ''}
            ${bottom ? `--input-restriction-bottom: ${bottom};` : ''}
            ${right ? `--input-restriction-right: ${right};` : ''}
            ${mobileBottom ? `--input-restriction-mobile-bottom: ${mobileBottom};` : ''}
            ${mobileRight ? `--input-restriction-mobile-right: ${mobileRight};` : ''}
            ${restrictionFontSize ? `--input-restriction-font-size: ${restrictionFontSize};` : ''}
            ${restrictionFontWeight ? `--input-restriction-font-weight: ${restrictionFontWeight};` : ''}
            ${restrictionLineHeight ? `--input-restriction-line-height: ${restrictionLineHeight};` : ''}
            ${compileInputBorder(border)}
        }
    `;
}
exports.default = compileInputStyleString;
