"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    :root,
    .pythia-surveys-root[data-theme='dark'] {
        --scale-border: var(--scale-option-border-width) var(--scale-option-border-style)
            var(--scale-option-border-color);
        --scale-border-hover: var(--scale-option-border-hover-width)
            var(--scale-option-border-style) var(--scale-option-border-hover-color);
        --scale-border-active: var(--scale-option-border-selected-width)
            var(--scale-option-border-style) var(--scale-option-border-selected-color);
        --scale-labels-color: #777;
    }

    .scale {
        margin-top: 2.8em;
        display: flex;
        justify-content: var(--scale-options-position);
    }

    .scale.scale_compoundQuestion {
        margin-top: 0;
    }

    .scale__wrapper.scale__wrapper_count_2 {
        max-width: var(--scale-options-count-2-max-width);
    }

    .scale__wrapper.scale__wrapper_count_3 {
        max-width: var(--scale-options-count-3-max-width);
    }

    .scale__wrapper {
        max-width: var(--scale-options-max-width);
        flex: 1;
    }

    .scale__wrapper_manyOptions_yes {
        padding-inline: var(--scale-many-options-padding-inline);
    }

    .scale__wrapper_manyOptions_no {
        padding-inline: var(--scale-options-padding-inline);
    }

    .scale__options {
        border-collapse: collapse;
        display: flex;
        font-size: 14px;
        justify-content: var(--scale-options-inner-position);
        margin-bottom: var(--scale-labels-margin-bottom);
        text-align: center;
    }

    .scale__labels + .scale__options-wrapper {
        margin-top: var(--scale-labels-margin-top);
    }

    .scale__option.checked .scale__option-label {
        outline: var(--scale-border-active);
        background: var(--scale-option-selected-bg);
        color: var(--scale-option-selected-color);
        border-radius: var(--scale-option-border-selected-radius);
    }

    .scale__sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    .scale__option {
        flex: var(--scale-option-flex);
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .scale__option-label {
        border: 0;
        padding: 0;
        font-size: var(--scale-option-font-size);
        line-height: var(--scale-option-line-height);
        outline-offset: -1px;
        outline: var(--scale-border);
        background: var(--scale-option-bg);
        width: var(--scale-option-width);
        border-radius: var(--scale-option-border-radius);
        color: var(--scale-option-color);
        &:focus-visible {
            outline: 2px solid var(--scale-option-focus-visible-border-color);
            outline-offset: 2px;
        }
    }

    .scale__option-label:hover {
        outline: var(--scale-border-hover);
        background: var(--scale-option-hover-bg);
        color: var(--scale-option-hover-color);
        border-radius: var(--scale-option-border-hover-radius);
        z-index: 1;
    }

    .scale__labels {
        display: table;
        table-layout: fixed;

        width: 100%;

        margin-bottom: 0.3em;
        font-size: var(--scale-labels-font-size);
        line-height: var(--scale-labels-line-height);
        color: var(--scale-labels-color);
    }

    .scale__labels_bottom {
        margin-bottom: 0;
        margin-top: 0.3em;
    }

    .scale__label {
        display: table-cell;
        vertical-align: middle;

        width: 33%;

        overflow: hidden;
        text-overflow: ellipsis;
    }

    .scale__label_position_left {
        text-align: start;
    }

    .scale__label_position_center {
        text-align: center;
    }

    .scale__label_position_right {
        text-align: end;
    }

    /* todo не понял что с этим делать (в конструкторе эти стили без important перебиваются) */
    .special-options-as-buttons__button {
        margin-top: 1em !important;
        margin-bottom: 1em !important;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .scale__special-options {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    /* Стили transparent */
    .question_theme_transparent .scale__options {
        max-width: 54em;
    }

    /* Стили video */
    .question_theme_video .scale__label {
        color: #f6f5f3;
    }

    .question_theme_video .scale__option .scale__contatiner {
        border: none;
        border-radius: 3px;
        color: #f6f5f3;
        background: rgba(255, 255, 255, 0.1);
        width: 90%;
        margin-inline-start: 5%;
    }

    .question_theme_video .scale__option:first-of-type .scale__contatiner {
        width: 95%;
        margin-inline-start: 0;
    }

    .question_theme_video .scale__option:last-child .scale__contatiner {
        width: 95%;
        border: none;
    }

    .question_theme_video .scale__option.checked {
        background: none;
    }

    .question_theme_video .scale__option.checked .scale__contatiner {
        background: white;
        color: black;
    }

    .scale__options-wrapper_with-checkboxes {
        display: flex;
    }

    .scale__options-with-checkboxes {
        display: flex;
        width: 100%;
        margin-inline-start: 4px;
    }

    .scale__option-with-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        margin-inline: 3px;
        background: rgba(176, 189, 214, 0.15);
        border-radius: 8px;
        height: inherit;
        position: relative;
    }

    .question__content_error_yes
        .scale__options-wrapper_with-checkboxes.scale__options-wrapper_has-error
        .scale__option-with-checkbox,
    .question__content_error_yes
        .scale__options-wrapper_with-checkboxes.scale__options-wrapper_has-error
        .scale__option-with-checkbox.scale__option-with-checkbox_noOpinionAnswer {
        background: rgba(221, 0, 0, 0.1);
    }

    .question__content_error_yes
        .scale__options-wrapper_with-checkboxes.scale__options-wrapper_has-error
        .scale__option-with-checkbox:hover,
    .question__content_error_yes
        .scale__options-wrapper_with-checkboxes.scale__options-wrapper_has-error
        .scale__option-with-checkbox.scale__option-with-checkbox_noOpinionAnswer:hover,
    .scale__option-with-checkbox:hover {
        background: rgba(176, 189, 214, 0.3);
        cursor: pointer;
    }

    .scale__option-with-checkbox .choice__option {
        margin-top: 0 !important;
        display: flex;
        justify-content: center;
        margin-inline-end: 0px;
    }

    .scale__option-with-checkbox .choice__option-control {
        font-size: 9px !important;
        -webkit-margin-end: 0 !important;
        margin-inline-end: 0 !important;
        border: 1px solid var(--view-default-fill-color-action) !important;
    }

    .scale__options-with-checkboxes .scale__option-with-checkbox {
        width: 100%;
        border-radius: 8px;
    }

    .scale__option-with-checkbox.scale__option-with-checkbox_noOpinionAnswer .choice__option {
        min-width: 125px;
        width: 125px;
    }

    .scale__option-with-checkbox_mobile {
        display: none;
    }

    .scale__options-wrapper_with-checkboxes .choice__option-label {
        background-color: transparent;
        width: 100%;
        border-radius: 8px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-inline: 3px;
        min-height: 32px;
    }

    .scale__options-wrapper_with-checkboxes .choice__option-control {
        top: 0 !important;
    }

    .scale__option-with-checkbox .choice__option,
    .scale__option-with-checkbox .scale__special-option {
        height: 100%;
    }

    .scale .special-options-as-buttons {
        margin-top: calc(var(--special-buttons-margin-top) + var(--scale-option-border-width));
    }

    @media (max-width: 640px) {
        .scale__options-wrapper_with-checkboxes {
            flex-direction: column;
        }

        .scale__option-with-checkbox_desktop {
            display: none;
        }

        .scale__option-with-checkbox_mobile {
            display: block;
        }

        .scale__option-with-checkbox_noOpinionAnswer {
            margin-top: 10px;
        }

        .scale__options-with-checkboxes .scale__option-with-checkbox {
            height: 44px;
            max-width: calc((100% - 60px) / 6);
            margin-right: 8px;
        }

        .scale__options-with-checkboxes > .scale__option-with-checkbox:nth-child(6) {
            margin-right: 0px;
        }

        .scale__option-row-text {
            background: #eff2f7;
            border-radius: 12px;
            padding: 8px 12px;
            margin-bottom: 10px;
        }

        .scale__options-with-checkboxes .scale__option-with-checkbox {
            position: relative;
            margin-top: 32px;
        }

        .scale__options-with-checkboxes .scale__option-with-checkbox:before {
            position: absolute;
            content: attr(data-value);
            top: -25px;
        }

        .scale__option-with-checkbox_noOpinionAnswer {
            position: relative;
        }

        .scale__options-wrapper_with-checkboxes {
            margin-bottom: 20px;
        }

        .scale__option-with-checkbox_noOpinionAnswer {
            background: none;
            border-radius: 12px;
            border: 1px solid #eff2f7;
            text-align: center;
            padding: 8px 12px;
            box-sizing: border-box;
            word-break: break-word;
            border: 1px solid rgba(165, 177, 202, 0.3);
        }

        .scale__option-with-checkbox_checked.scale__option-with-checkbox_noOpinionAnswer {
            background: var(--view-default-fill-color-action);
        }

        .scale__option-with-checkbox_noOpinionAnswer > .choice__option-label {
            display: none !important;
        }

        .scale__special-option {
            display: none;
        }

        .scale__options-with-checkboxes {
            flex-wrap: wrap;
            margin-inline-start: 0px;
        }
    }

    @media (max-width: 400px) {
        .scale__options {
            font-size: var(--scale-mobile-few-options-font-size);
        }

        .scale__options.scale__options_manyOptions {
            font-size: var(--scale-mobile-many-options-font-size);
            margin-bottom: var(--scale-mobile-many-options-margin-bottom);
        }

        .scale__options.scale__options_manyOptions .scale__option-label {
            width: var(--scale-mobile-many-options-label-width);
            line-height: var(--scale-mobile-many-options-label-line-height);
        }
    }
`;
