"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    width: 180px;
    min-width: 180px;
    word-break: break-word;

    .table-attachment-block__image-container {
        position: relative;
    }

    .table-attachment-block__image-container:hover > .table-attachment-block__zoom {
        display: block;
    }

    .table-attachment-block__image-container_zoomable:hover {
        cursor: pointer;
    }

    .table-attachment-block__zoom {
        position: absolute;
        top: 20px;
        right: 10px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMjUgMTIuNUMyLjc5ODIyIDEyLjUgMCA5LjcwMTc4IDAgNi4yNUMwIDIuNzk4MjIgMi43OTgyMiAwIDYuMjUgMEM5LjcwMTc4IDAgMTIuNSAyLjc5ODIyIDEyLjUgNi4yNUMxMi41IDcuNjE0OTcgMTIuMDYyNCA4Ljg3Nzc1IDExLjMxOTkgOS45MDU3TDE1LjIwNzEgMTMuNzkyOUMxNS41OTc2IDE0LjE4MzQgMTUuNTk3NiAxNC44MTY2IDE1LjIwNzEgMTUuMjA3MUMxNC44MTY2IDE1LjU5NzYgMTQuMTgzNCAxNS41OTc2IDEzLjc5MjkgMTUuMjA3MUw5LjkwNTcgMTEuMzE5OUM4Ljg3Nzc1IDEyLjA2MjQgNy42MTQ5NyAxMi41IDYuMjUgMTIuNVpNNi4yNSAxMC41QzguNTk3MjEgMTAuNSAxMC41IDguNTk3MjEgMTAuNSA2LjI1QzEwLjUgMy45MDI3OSA4LjU5NzIxIDIgNi4yNSAyQzMuOTAyNzkgMiAyIDMuOTAyNzkgMiA2LjI1QzIgOC41OTcyMSAzLjkwMjc5IDEwLjUgNi4yNSAxMC41WiIgZmlsbD0iIzJGMzc0NyIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 15px;
        background-color: #f6f8fb;
        border-radius: 12px;
        width: 36px;
        height: 36px;
        display: none;
    }

    .table-attachment-block__column-image {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        object-fit: contain;
        border-radius: 14px;
        box-sizing: border-box;
        border: 1px solid rgba(165, 177, 202, 0.3);
        margin-top: 10px;
    }

    .table-attachment-block__option-row-text > .markdown p {
        color: var(--text-color) !important;
        line-height: inherit !important;
        font-size: 16px !important;
        margin: 0 !important;
    }

    .table-attachment-block_rowBadge {
        margin-bottom: 10px;
    }

    @media (max-width: 640px) {
        width: 100%;

        .table-attachment-block__column-image {
            height: 112px;
            margin-top: 0px;
        }

        .table-attachment-block__option-row-text {
            background: #eff2f7;
            border-radius: 12px;
            padding: 8px 12px;
            margin-bottom: 10px;
        }

        .table-attachment-block__image-container:hover::after {
            top: 10px;
        }

        .table-attachment-block__image-container:hover > .table-attachment-block__zoom {
            display: none;
        }
    }
`;
