"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFeedback = exports.getSurveyStateFromConfig = exports.getSurveyStateFromLS = exports.getSurveyInterviewKey = exports.getSlugFromPathname = void 0;
const actions_1 = require("src/redux/actions");
const reducers_1 = require("../redux/reducers");
const location_1 = require("./location");
const survey_config_1 = require("./survey-config");
const getSlugFromPathname = ({ pathname }) => pathname.split('/').filter(Boolean).pop();
exports.getSlugFromPathname = getSlugFromPathname;
const getSurveyInterviewKey = ({ search, pathname }) => {
    const interviewId = (0, location_1.getQueryParams)(search).ivid;
    const slug = (0, exports.getSlugFromPathname)({ pathname });
    return interviewId || slug;
};
exports.getSurveyInterviewKey = getSurveyInterviewKey;
function getSurveyStateFromLS(lsClient, { pathname, search }) {
    const interviewKey = (0, exports.getSurveyInterviewKey)({ pathname, search });
    return lsClient && lsClient.get(interviewKey);
}
exports.getSurveyStateFromLS = getSurveyStateFromLS;
function getSurveyStateFromConfig({ slug, surveyConfig, location }) {
    if (surveyConfig && surveyConfig.pageOrder) {
        const defaultStoreState = (0, reducers_1.getDefaultStoreState)(location);
        const state = (0, reducers_1.reducer)(defaultStoreState, (0, actions_1.loadSurveyFromConfig)(slug, (0, survey_config_1.transformRawSurveyConfig)(surveyConfig), 200));
        return state;
    }
    return null;
}
exports.getSurveyStateFromConfig = getSurveyStateFromConfig;
function isFeedback() {
    return window.location.href.includes('/feedback');
}
exports.isFeedback = isFeedback;
